import { default as index751ZMW6oMeMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/cases-de-sucesso/index.vue?macro=true";
import { default as conta_45bancariaaDdqW3rojeMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/completar-cadastro/conta-bancaria.vue?macro=true";
import { default as dados_45documento2eTkkGrlyfMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/completar-cadastro/dados-documento.vue?macro=true";
import { default as dados_45patrimoniaisARMk2G3MV8Meta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/completar-cadastro/dados-patrimoniais.vue?macro=true";
import { default as declaracoesVAyFchDrdqMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/completar-cadastro/declaracoes.vue?macro=true";
import { default as documentosNLL51XUNUNMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/completar-cadastro/documentos.vue?macro=true";
import { default as enderecorHOEv28EBrMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/completar-cadastro/endereco.vue?macro=true";
import { default as indexEQ3CjchlQzMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/completar-cadastro/index.vue?macro=true";
import { default as informacoes_45pessoaisnKMOKoh813Meta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/completar-cadastro/informacoes-pessoais.vue?macro=true";
import { default as _91partner_93rRhyEJgGfYMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/crie-sua-conta/[partner].vue?macro=true";
import { default as indexRKutDECGu4Meta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/index.vue?macro=true";
import { default as confirmacaoIRZpFqy80GMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/investir/[operationId]/confirmacao.vue?macro=true";
import { default as detalhesPLMGlMRf4fMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/investir/[operationId]/detalhes.vue?macro=true";
import { default as enquadramentoqr9flrnCuFMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/investir/[operationId]/enquadramento.vue?macro=true";
import { default as indexVpl6EzGw6XMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/investir/[operationId]/index.vue?macro=true";
import { default as confirmar_45contaeT1Y1n1rthMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/investir/[operationId]/inicio/confirmar-conta.vue?macro=true";
import { default as criar_45contaaWMSNcdW1qMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/investir/[operationId]/inicio/criar-conta.vue?macro=true";
import { default as indexcjRmAWjyG9Meta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/investir/[operationId]/inicio/index.vue?macro=true";
import { default as inicioQIb0Foar2VMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/investir/[operationId]/inicio.vue?macro=true";
import { default as _91indicationCode_93gdeeq0txW0Meta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/novo-investidor/[indicationCode].vue?macro=true";
import { default as _91operationId_933hpK9RF6ghMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/oportunidades/[operationId].vue?macro=true";
import { default as finalizadaszBaszAerVxMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/oportunidades/finalizadas.vue?macro=true";
import { default as historicoFr29oxOo8kMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/oportunidades/historico.vue?macro=true";
import { default as indexABtMJENC78Meta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/oportunidades/index.vue?macro=true";
import { default as _91operationId_93jWAIIVpOL3Meta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/oportunidades/parceiro/[operationId].vue?macro=true";
import { default as indexwxjQuKFBMZMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/originadoras/index.vue?macro=true";
import { default as _91slug_93dJ5IOFLcSRMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/ajuda/[slug].vue?macro=true";
import { default as indexks26KishOOMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/ajuda/index.vue?macro=true";
import { default as _91operationId_93rTYICZM5WiMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/detalhes-investimento/[operationId].vue?macro=true";
import { default as index7feu0g2AJhMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/index.vue?macro=true";
import { default as index0MO6boQPQyMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/indicacao/index.vue?macro=true";
import { default as indexAk6amMXd5wMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/ir/index.vue?macro=true";
import { default as indexylqRGUyZmNMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/pendentes/index.vue?macro=true";
import { default as index7lLVJP2xK3Meta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/alterar-email/index.vue?macro=true";
import { default as index2lr8RFY6ExMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/alterar-senha/index.vue?macro=true";
import { default as indexB8dgSEc2u1Meta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/alterar-telefone/index.vue?macro=true";
import { default as adicionarpQg4IICtVyMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/chave-pix/adicionar.vue?macro=true";
import { default as indexb0t2x9VlvrMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/chave-pix/index.vue?macro=true";
import { default as editar7amuaBat0GMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/[id]/editar.vue?macro=true";
import { default as adicionar2OctZTLzhcMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/adicionar.vue?macro=true";
import { default as indexB1Qo50syTOMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/index.vue?macro=true";
import { default as indexfBXFKOwYtyMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/dados-patrimoniais/index.vue?macro=true";
import { default as indexZLQz4OY2sLMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/dados-pessoais/index.vue?macro=true";
import { default as indexKn8wgv9B3wMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/desativar-conta/index.vue?macro=true";
import { default as indexiGdCk5HyS9Meta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/documentos/index.vue?macro=true";
import { default as indexEuHbWTiSfsMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/endereco/index.vue?macro=true";
import { default as indexj8B46jvtPaMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/index.vue?macro=true";
import { default as _91assetSymbol_93sGpfxTB4dSMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/wallet/[assetSymbol].vue?macro=true";
import { default as depositoVNq4WB55geMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/wallet/deposito.vue?macro=true";
import { default as indexGtGby50VRxMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/wallet/index.vue?macro=true";
import { default as resgateJXYxaIBLvgMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/wallet/resgate.vue?macro=true";
import { default as indexDBDiL84xhyMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/parceiros/index.vue?macro=true";
import { default as indexAbfMKVUqVMMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/politica-de-privacidade/index.vue?macro=true";
import { default as indexSt9kajQGpAMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/quem-somos/index.vue?macro=true";
import { default as index4aKDzXBmqrMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/remocao-de-conta/index.vue?macro=true";
import { default as _91signupCode_938qTkyK1vCkMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/seja-investidor/[signupCode].vue?macro=true";
import { default as indexwzOzDTfeDfMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/termos-de-uso/index.vue?macro=true";
import { default as indexrsdSnDkn3cMeta } from "/codebuild/output/src1186922866/src/hcp-web-platform/pages/tokenizacao/index.vue?macro=true";
export default [
  {
    name: index751ZMW6oMeMeta?.name ?? "cases-de-sucesso",
    path: index751ZMW6oMeMeta?.path ?? "/cases-de-sucesso",
    meta: index751ZMW6oMeMeta || {},
    alias: index751ZMW6oMeMeta?.alias || [],
    redirect: index751ZMW6oMeMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/cases-de-sucesso/index.vue").then(m => m.default || m)
  },
  {
    name: conta_45bancariaaDdqW3rojeMeta?.name ?? "completar-cadastro-conta-bancaria",
    path: conta_45bancariaaDdqW3rojeMeta?.path ?? "/completar-cadastro/conta-bancaria",
    meta: conta_45bancariaaDdqW3rojeMeta || {},
    alias: conta_45bancariaaDdqW3rojeMeta?.alias || [],
    redirect: conta_45bancariaaDdqW3rojeMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/completar-cadastro/conta-bancaria.vue").then(m => m.default || m)
  },
  {
    name: dados_45documento2eTkkGrlyfMeta?.name ?? "completar-cadastro-dados-documento",
    path: dados_45documento2eTkkGrlyfMeta?.path ?? "/completar-cadastro/dados-documento",
    meta: dados_45documento2eTkkGrlyfMeta || {},
    alias: dados_45documento2eTkkGrlyfMeta?.alias || [],
    redirect: dados_45documento2eTkkGrlyfMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/completar-cadastro/dados-documento.vue").then(m => m.default || m)
  },
  {
    name: dados_45patrimoniaisARMk2G3MV8Meta?.name ?? "completar-cadastro-dados-patrimoniais",
    path: dados_45patrimoniaisARMk2G3MV8Meta?.path ?? "/completar-cadastro/dados-patrimoniais",
    meta: dados_45patrimoniaisARMk2G3MV8Meta || {},
    alias: dados_45patrimoniaisARMk2G3MV8Meta?.alias || [],
    redirect: dados_45patrimoniaisARMk2G3MV8Meta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/completar-cadastro/dados-patrimoniais.vue").then(m => m.default || m)
  },
  {
    name: declaracoesVAyFchDrdqMeta?.name ?? "completar-cadastro-declaracoes",
    path: declaracoesVAyFchDrdqMeta?.path ?? "/completar-cadastro/declaracoes",
    meta: declaracoesVAyFchDrdqMeta || {},
    alias: declaracoesVAyFchDrdqMeta?.alias || [],
    redirect: declaracoesVAyFchDrdqMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/completar-cadastro/declaracoes.vue").then(m => m.default || m)
  },
  {
    name: documentosNLL51XUNUNMeta?.name ?? "completar-cadastro-documentos",
    path: documentosNLL51XUNUNMeta?.path ?? "/completar-cadastro/documentos",
    meta: documentosNLL51XUNUNMeta || {},
    alias: documentosNLL51XUNUNMeta?.alias || [],
    redirect: documentosNLL51XUNUNMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/completar-cadastro/documentos.vue").then(m => m.default || m)
  },
  {
    name: enderecorHOEv28EBrMeta?.name ?? "completar-cadastro-endereco",
    path: enderecorHOEv28EBrMeta?.path ?? "/completar-cadastro/endereco",
    meta: enderecorHOEv28EBrMeta || {},
    alias: enderecorHOEv28EBrMeta?.alias || [],
    redirect: enderecorHOEv28EBrMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/completar-cadastro/endereco.vue").then(m => m.default || m)
  },
  {
    name: indexEQ3CjchlQzMeta?.name ?? "completar-cadastro",
    path: indexEQ3CjchlQzMeta?.path ?? "/completar-cadastro",
    meta: indexEQ3CjchlQzMeta || {},
    alias: indexEQ3CjchlQzMeta?.alias || [],
    redirect: indexEQ3CjchlQzMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/completar-cadastro/index.vue").then(m => m.default || m)
  },
  {
    name: informacoes_45pessoaisnKMOKoh813Meta?.name ?? "completar-cadastro-informacoes-pessoais",
    path: informacoes_45pessoaisnKMOKoh813Meta?.path ?? "/completar-cadastro/informacoes-pessoais",
    meta: informacoes_45pessoaisnKMOKoh813Meta || {},
    alias: informacoes_45pessoaisnKMOKoh813Meta?.alias || [],
    redirect: informacoes_45pessoaisnKMOKoh813Meta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/completar-cadastro/informacoes-pessoais.vue").then(m => m.default || m)
  },
  {
    name: _91partner_93rRhyEJgGfYMeta?.name ?? "crie-sua-conta-partner",
    path: _91partner_93rRhyEJgGfYMeta?.path ?? "/crie-sua-conta/:partner()",
    meta: _91partner_93rRhyEJgGfYMeta || {},
    alias: _91partner_93rRhyEJgGfYMeta?.alias || [],
    redirect: _91partner_93rRhyEJgGfYMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/crie-sua-conta/[partner].vue").then(m => m.default || m)
  },
  {
    name: indexRKutDECGu4Meta?.name ?? "index",
    path: indexRKutDECGu4Meta?.path ?? "/",
    meta: indexRKutDECGu4Meta || {},
    alias: indexRKutDECGu4Meta?.alias || [],
    redirect: indexRKutDECGu4Meta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/index.vue").then(m => m.default || m)
  },
  {
    name: confirmacaoIRZpFqy80GMeta?.name ?? "investir-operationId-confirmacao",
    path: confirmacaoIRZpFqy80GMeta?.path ?? "/investir/:operationId()/confirmacao",
    meta: confirmacaoIRZpFqy80GMeta || {},
    alias: confirmacaoIRZpFqy80GMeta?.alias || [],
    redirect: confirmacaoIRZpFqy80GMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/investir/[operationId]/confirmacao.vue").then(m => m.default || m)
  },
  {
    name: detalhesPLMGlMRf4fMeta?.name ?? "investir-operationId-detalhes",
    path: detalhesPLMGlMRf4fMeta?.path ?? "/investir/:operationId()/detalhes",
    meta: detalhesPLMGlMRf4fMeta || {},
    alias: detalhesPLMGlMRf4fMeta?.alias || [],
    redirect: detalhesPLMGlMRf4fMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/investir/[operationId]/detalhes.vue").then(m => m.default || m)
  },
  {
    name: enquadramentoqr9flrnCuFMeta?.name ?? "investir-operationId-enquadramento",
    path: enquadramentoqr9flrnCuFMeta?.path ?? "/investir/:operationId()/enquadramento",
    meta: enquadramentoqr9flrnCuFMeta || {},
    alias: enquadramentoqr9flrnCuFMeta?.alias || [],
    redirect: enquadramentoqr9flrnCuFMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/investir/[operationId]/enquadramento.vue").then(m => m.default || m)
  },
  {
    name: indexVpl6EzGw6XMeta?.name ?? "investir-operationId",
    path: indexVpl6EzGw6XMeta?.path ?? "/investir/:operationId()",
    meta: indexVpl6EzGw6XMeta || {},
    alias: indexVpl6EzGw6XMeta?.alias || [],
    redirect: indexVpl6EzGw6XMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/investir/[operationId]/index.vue").then(m => m.default || m)
  },
  {
    name: inicioQIb0Foar2VMeta?.name ?? undefined,
    path: inicioQIb0Foar2VMeta?.path ?? "/investir/:operationId()/inicio",
    meta: inicioQIb0Foar2VMeta || {},
    alias: inicioQIb0Foar2VMeta?.alias || [],
    redirect: inicioQIb0Foar2VMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/investir/[operationId]/inicio.vue").then(m => m.default || m),
    children: [
  {
    name: confirmar_45contaeT1Y1n1rthMeta?.name ?? "investir-operationId-inicio-confirmar-conta",
    path: confirmar_45contaeT1Y1n1rthMeta?.path ?? "confirmar-conta",
    meta: confirmar_45contaeT1Y1n1rthMeta || {},
    alias: confirmar_45contaeT1Y1n1rthMeta?.alias || [],
    redirect: confirmar_45contaeT1Y1n1rthMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/investir/[operationId]/inicio/confirmar-conta.vue").then(m => m.default || m)
  },
  {
    name: criar_45contaaWMSNcdW1qMeta?.name ?? "investir-operationId-inicio-criar-conta",
    path: criar_45contaaWMSNcdW1qMeta?.path ?? "criar-conta",
    meta: criar_45contaaWMSNcdW1qMeta || {},
    alias: criar_45contaaWMSNcdW1qMeta?.alias || [],
    redirect: criar_45contaaWMSNcdW1qMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/investir/[operationId]/inicio/criar-conta.vue").then(m => m.default || m)
  },
  {
    name: indexcjRmAWjyG9Meta?.name ?? "investir-operationId-inicio",
    path: indexcjRmAWjyG9Meta?.path ?? "",
    meta: indexcjRmAWjyG9Meta || {},
    alias: indexcjRmAWjyG9Meta?.alias || [],
    redirect: indexcjRmAWjyG9Meta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/investir/[operationId]/inicio/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91indicationCode_93gdeeq0txW0Meta?.name ?? "novo-investidor-indicationCode",
    path: _91indicationCode_93gdeeq0txW0Meta?.path ?? "/novo-investidor/:indicationCode()",
    meta: _91indicationCode_93gdeeq0txW0Meta || {},
    alias: _91indicationCode_93gdeeq0txW0Meta?.alias || [],
    redirect: _91indicationCode_93gdeeq0txW0Meta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/novo-investidor/[indicationCode].vue").then(m => m.default || m)
  },
  {
    name: _91operationId_933hpK9RF6ghMeta?.name ?? "oportunidades-operationId",
    path: _91operationId_933hpK9RF6ghMeta?.path ?? "/oportunidades/:operationId()",
    meta: _91operationId_933hpK9RF6ghMeta || {},
    alias: _91operationId_933hpK9RF6ghMeta?.alias || [],
    redirect: _91operationId_933hpK9RF6ghMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/oportunidades/[operationId].vue").then(m => m.default || m)
  },
  {
    name: finalizadaszBaszAerVxMeta?.name ?? "oportunidades-finalizadas",
    path: finalizadaszBaszAerVxMeta?.path ?? "/oportunidades/finalizadas",
    meta: finalizadaszBaszAerVxMeta || {},
    alias: finalizadaszBaszAerVxMeta?.alias || [],
    redirect: finalizadaszBaszAerVxMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/oportunidades/finalizadas.vue").then(m => m.default || m)
  },
  {
    name: historicoFr29oxOo8kMeta?.name ?? "oportunidades-historico",
    path: historicoFr29oxOo8kMeta?.path ?? "/oportunidades/historico",
    meta: historicoFr29oxOo8kMeta || {},
    alias: historicoFr29oxOo8kMeta?.alias || [],
    redirect: historicoFr29oxOo8kMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/oportunidades/historico.vue").then(m => m.default || m)
  },
  {
    name: indexABtMJENC78Meta?.name ?? "oportunidades",
    path: indexABtMJENC78Meta?.path ?? "/oportunidades",
    meta: indexABtMJENC78Meta || {},
    alias: indexABtMJENC78Meta?.alias || [],
    redirect: indexABtMJENC78Meta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/oportunidades/index.vue").then(m => m.default || m)
  },
  {
    name: _91operationId_93jWAIIVpOL3Meta?.name ?? "oportunidades-parceiro-operationId",
    path: _91operationId_93jWAIIVpOL3Meta?.path ?? "/oportunidades/parceiro/:operationId()",
    meta: _91operationId_93jWAIIVpOL3Meta || {},
    alias: _91operationId_93jWAIIVpOL3Meta?.alias || [],
    redirect: _91operationId_93jWAIIVpOL3Meta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/oportunidades/parceiro/[operationId].vue").then(m => m.default || m)
  },
  {
    name: indexwxjQuKFBMZMeta?.name ?? "originadoras",
    path: indexwxjQuKFBMZMeta?.path ?? "/originadoras",
    meta: indexwxjQuKFBMZMeta || {},
    alias: indexwxjQuKFBMZMeta?.alias || [],
    redirect: indexwxjQuKFBMZMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/originadoras/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dJ5IOFLcSRMeta?.name ?? "painel-ajuda-slug",
    path: _91slug_93dJ5IOFLcSRMeta?.path ?? "/painel/ajuda/:slug()",
    meta: _91slug_93dJ5IOFLcSRMeta || {},
    alias: _91slug_93dJ5IOFLcSRMeta?.alias || [],
    redirect: _91slug_93dJ5IOFLcSRMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/ajuda/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexks26KishOOMeta?.name ?? "painel-ajuda",
    path: indexks26KishOOMeta?.path ?? "/painel/ajuda",
    meta: indexks26KishOOMeta || {},
    alias: indexks26KishOOMeta?.alias || [],
    redirect: indexks26KishOOMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/ajuda/index.vue").then(m => m.default || m)
  },
  {
    name: _91operationId_93rTYICZM5WiMeta?.name ?? "painel-detalhes-investimento-operationId",
    path: _91operationId_93rTYICZM5WiMeta?.path ?? "/painel/detalhes-investimento/:operationId()",
    meta: _91operationId_93rTYICZM5WiMeta || {},
    alias: _91operationId_93rTYICZM5WiMeta?.alias || [],
    redirect: _91operationId_93rTYICZM5WiMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/detalhes-investimento/[operationId].vue").then(m => m.default || m)
  },
  {
    name: index7feu0g2AJhMeta?.name ?? "painel",
    path: index7feu0g2AJhMeta?.path ?? "/painel",
    meta: index7feu0g2AJhMeta || {},
    alias: index7feu0g2AJhMeta?.alias || [],
    redirect: index7feu0g2AJhMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/index.vue").then(m => m.default || m)
  },
  {
    name: index0MO6boQPQyMeta?.name ?? "painel-indicacao",
    path: index0MO6boQPQyMeta?.path ?? "/painel/indicacao",
    meta: index0MO6boQPQyMeta || {},
    alias: index0MO6boQPQyMeta?.alias || [],
    redirect: index0MO6boQPQyMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/indicacao/index.vue").then(m => m.default || m)
  },
  {
    name: indexAk6amMXd5wMeta?.name ?? "painel-ir",
    path: indexAk6amMXd5wMeta?.path ?? "/painel/ir",
    meta: indexAk6amMXd5wMeta || {},
    alias: indexAk6amMXd5wMeta?.alias || [],
    redirect: indexAk6amMXd5wMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/ir/index.vue").then(m => m.default || m)
  },
  {
    name: indexylqRGUyZmNMeta?.name ?? "painel-pendentes",
    path: indexylqRGUyZmNMeta?.path ?? "/painel/pendentes",
    meta: indexylqRGUyZmNMeta || {},
    alias: indexylqRGUyZmNMeta?.alias || [],
    redirect: indexylqRGUyZmNMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/pendentes/index.vue").then(m => m.default || m)
  },
  {
    name: index7lLVJP2xK3Meta?.name ?? "painel-perfil-alterar-email",
    path: index7lLVJP2xK3Meta?.path ?? "/painel/perfil/alterar-email",
    meta: index7lLVJP2xK3Meta || {},
    alias: index7lLVJP2xK3Meta?.alias || [],
    redirect: index7lLVJP2xK3Meta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/alterar-email/index.vue").then(m => m.default || m)
  },
  {
    name: index2lr8RFY6ExMeta?.name ?? "painel-perfil-alterar-senha",
    path: index2lr8RFY6ExMeta?.path ?? "/painel/perfil/alterar-senha",
    meta: index2lr8RFY6ExMeta || {},
    alias: index2lr8RFY6ExMeta?.alias || [],
    redirect: index2lr8RFY6ExMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/alterar-senha/index.vue").then(m => m.default || m)
  },
  {
    name: indexB8dgSEc2u1Meta?.name ?? "painel-perfil-alterar-telefone",
    path: indexB8dgSEc2u1Meta?.path ?? "/painel/perfil/alterar-telefone",
    meta: indexB8dgSEc2u1Meta || {},
    alias: indexB8dgSEc2u1Meta?.alias || [],
    redirect: indexB8dgSEc2u1Meta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/alterar-telefone/index.vue").then(m => m.default || m)
  },
  {
    name: adicionarpQg4IICtVyMeta?.name ?? "painel-perfil-chave-pix-adicionar",
    path: adicionarpQg4IICtVyMeta?.path ?? "/painel/perfil/chave-pix/adicionar",
    meta: adicionarpQg4IICtVyMeta || {},
    alias: adicionarpQg4IICtVyMeta?.alias || [],
    redirect: adicionarpQg4IICtVyMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/chave-pix/adicionar.vue").then(m => m.default || m)
  },
  {
    name: indexb0t2x9VlvrMeta?.name ?? "painel-perfil-chave-pix",
    path: indexb0t2x9VlvrMeta?.path ?? "/painel/perfil/chave-pix",
    meta: indexb0t2x9VlvrMeta || {},
    alias: indexb0t2x9VlvrMeta?.alias || [],
    redirect: indexb0t2x9VlvrMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/chave-pix/index.vue").then(m => m.default || m)
  },
  {
    name: editar7amuaBat0GMeta?.name ?? "painel-perfil-dados-bancarios-id-editar",
    path: editar7amuaBat0GMeta?.path ?? "/painel/perfil/dados-bancarios/:id()/editar",
    meta: editar7amuaBat0GMeta || {},
    alias: editar7amuaBat0GMeta?.alias || [],
    redirect: editar7amuaBat0GMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/[id]/editar.vue").then(m => m.default || m)
  },
  {
    name: adicionar2OctZTLzhcMeta?.name ?? "painel-perfil-dados-bancarios-adicionar",
    path: adicionar2OctZTLzhcMeta?.path ?? "/painel/perfil/dados-bancarios/adicionar",
    meta: adicionar2OctZTLzhcMeta || {},
    alias: adicionar2OctZTLzhcMeta?.alias || [],
    redirect: adicionar2OctZTLzhcMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/adicionar.vue").then(m => m.default || m)
  },
  {
    name: indexB1Qo50syTOMeta?.name ?? "painel-perfil-dados-bancarios",
    path: indexB1Qo50syTOMeta?.path ?? "/painel/perfil/dados-bancarios",
    meta: indexB1Qo50syTOMeta || {},
    alias: indexB1Qo50syTOMeta?.alias || [],
    redirect: indexB1Qo50syTOMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/index.vue").then(m => m.default || m)
  },
  {
    name: indexfBXFKOwYtyMeta?.name ?? "painel-perfil-dados-patrimoniais",
    path: indexfBXFKOwYtyMeta?.path ?? "/painel/perfil/dados-patrimoniais",
    meta: indexfBXFKOwYtyMeta || {},
    alias: indexfBXFKOwYtyMeta?.alias || [],
    redirect: indexfBXFKOwYtyMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/dados-patrimoniais/index.vue").then(m => m.default || m)
  },
  {
    name: indexZLQz4OY2sLMeta?.name ?? "painel-perfil-dados-pessoais",
    path: indexZLQz4OY2sLMeta?.path ?? "/painel/perfil/dados-pessoais",
    meta: indexZLQz4OY2sLMeta || {},
    alias: indexZLQz4OY2sLMeta?.alias || [],
    redirect: indexZLQz4OY2sLMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/dados-pessoais/index.vue").then(m => m.default || m)
  },
  {
    name: indexKn8wgv9B3wMeta?.name ?? "painel-perfil-desativar-conta",
    path: indexKn8wgv9B3wMeta?.path ?? "/painel/perfil/desativar-conta",
    meta: indexKn8wgv9B3wMeta || {},
    alias: indexKn8wgv9B3wMeta?.alias || [],
    redirect: indexKn8wgv9B3wMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/desativar-conta/index.vue").then(m => m.default || m)
  },
  {
    name: indexiGdCk5HyS9Meta?.name ?? "painel-perfil-documentos",
    path: indexiGdCk5HyS9Meta?.path ?? "/painel/perfil/documentos",
    meta: indexiGdCk5HyS9Meta || {},
    alias: indexiGdCk5HyS9Meta?.alias || [],
    redirect: indexiGdCk5HyS9Meta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/documentos/index.vue").then(m => m.default || m)
  },
  {
    name: indexEuHbWTiSfsMeta?.name ?? "painel-perfil-endereco",
    path: indexEuHbWTiSfsMeta?.path ?? "/painel/perfil/endereco",
    meta: indexEuHbWTiSfsMeta || {},
    alias: indexEuHbWTiSfsMeta?.alias || [],
    redirect: indexEuHbWTiSfsMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/endereco/index.vue").then(m => m.default || m)
  },
  {
    name: indexj8B46jvtPaMeta?.name ?? "painel-perfil",
    path: indexj8B46jvtPaMeta?.path ?? "/painel/perfil",
    meta: indexj8B46jvtPaMeta || {},
    alias: indexj8B46jvtPaMeta?.alias || [],
    redirect: indexj8B46jvtPaMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/perfil/index.vue").then(m => m.default || m)
  },
  {
    name: _91assetSymbol_93sGpfxTB4dSMeta?.name ?? "painel-wallet-assetSymbol",
    path: _91assetSymbol_93sGpfxTB4dSMeta?.path ?? "/painel/wallet/:assetSymbol()",
    meta: _91assetSymbol_93sGpfxTB4dSMeta || {},
    alias: _91assetSymbol_93sGpfxTB4dSMeta?.alias || [],
    redirect: _91assetSymbol_93sGpfxTB4dSMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/wallet/[assetSymbol].vue").then(m => m.default || m)
  },
  {
    name: depositoVNq4WB55geMeta?.name ?? "painel-wallet-deposito",
    path: depositoVNq4WB55geMeta?.path ?? "/painel/wallet/deposito",
    meta: depositoVNq4WB55geMeta || {},
    alias: depositoVNq4WB55geMeta?.alias || [],
    redirect: depositoVNq4WB55geMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/wallet/deposito.vue").then(m => m.default || m)
  },
  {
    name: indexGtGby50VRxMeta?.name ?? "painel-wallet",
    path: indexGtGby50VRxMeta?.path ?? "/painel/wallet",
    meta: indexGtGby50VRxMeta || {},
    alias: indexGtGby50VRxMeta?.alias || [],
    redirect: indexGtGby50VRxMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/wallet/index.vue").then(m => m.default || m)
  },
  {
    name: resgateJXYxaIBLvgMeta?.name ?? "painel-wallet-resgate",
    path: resgateJXYxaIBLvgMeta?.path ?? "/painel/wallet/resgate",
    meta: resgateJXYxaIBLvgMeta || {},
    alias: resgateJXYxaIBLvgMeta?.alias || [],
    redirect: resgateJXYxaIBLvgMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/painel/wallet/resgate.vue").then(m => m.default || m)
  },
  {
    name: indexDBDiL84xhyMeta?.name ?? "parceiros",
    path: indexDBDiL84xhyMeta?.path ?? "/parceiros",
    meta: indexDBDiL84xhyMeta || {},
    alias: indexDBDiL84xhyMeta?.alias || [],
    redirect: indexDBDiL84xhyMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/parceiros/index.vue").then(m => m.default || m)
  },
  {
    name: indexAbfMKVUqVMMeta?.name ?? "politica-de-privacidade",
    path: indexAbfMKVUqVMMeta?.path ?? "/politica-de-privacidade",
    meta: indexAbfMKVUqVMMeta || {},
    alias: indexAbfMKVUqVMMeta?.alias || [],
    redirect: indexAbfMKVUqVMMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/politica-de-privacidade/index.vue").then(m => m.default || m)
  },
  {
    name: indexSt9kajQGpAMeta?.name ?? "quem-somos",
    path: indexSt9kajQGpAMeta?.path ?? "/quem-somos",
    meta: indexSt9kajQGpAMeta || {},
    alias: indexSt9kajQGpAMeta?.alias || [],
    redirect: indexSt9kajQGpAMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/quem-somos/index.vue").then(m => m.default || m)
  },
  {
    name: index4aKDzXBmqrMeta?.name ?? "remocao-de-conta",
    path: index4aKDzXBmqrMeta?.path ?? "/remocao-de-conta",
    meta: index4aKDzXBmqrMeta || {},
    alias: index4aKDzXBmqrMeta?.alias || [],
    redirect: index4aKDzXBmqrMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/remocao-de-conta/index.vue").then(m => m.default || m)
  },
  {
    name: _91signupCode_938qTkyK1vCkMeta?.name ?? "seja-investidor-signupCode",
    path: _91signupCode_938qTkyK1vCkMeta?.path ?? "/seja-investidor/:signupCode()",
    meta: _91signupCode_938qTkyK1vCkMeta || {},
    alias: _91signupCode_938qTkyK1vCkMeta?.alias || [],
    redirect: _91signupCode_938qTkyK1vCkMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/seja-investidor/[signupCode].vue").then(m => m.default || m)
  },
  {
    name: indexwzOzDTfeDfMeta?.name ?? "termos-de-uso",
    path: indexwzOzDTfeDfMeta?.path ?? "/termos-de-uso",
    meta: indexwzOzDTfeDfMeta || {},
    alias: indexwzOzDTfeDfMeta?.alias || [],
    redirect: indexwzOzDTfeDfMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/termos-de-uso/index.vue").then(m => m.default || m)
  },
  {
    name: indexrsdSnDkn3cMeta?.name ?? "tokenizacao",
    path: indexrsdSnDkn3cMeta?.path ?? "/tokenizacao",
    meta: indexrsdSnDkn3cMeta || {},
    alias: indexrsdSnDkn3cMeta?.alias || [],
    redirect: indexrsdSnDkn3cMeta?.redirect,
    component: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/pages/tokenizacao/index.vue").then(m => m.default || m)
  }
]