import validate from "/codebuild/output/src1186922866/src/hcp-web-platform/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45guard_45global from "/codebuild/output/src1186922866/src/hcp-web-platform/middleware/auth-guard.global.ts";
import pre_45refresh_45client_45global from "/codebuild/output/src1186922866/src/hcp-web-platform/middleware/pre-refresh.client.global.ts";
import manifest_45route_45rule from "/codebuild/output/src1186922866/src/hcp-web-platform/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45guard_45global,
  pre_45refresh_45client_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/codebuild/output/src1186922866/src/hcp-web-platform/middleware/auth.ts")
}